import React, { useContext } from 'react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './aboutus.css'
import AuthContext from '../context/AuthContext';
// Sample data for team members
const teamMembers = [
  { id: 1, photo: '../assets/photo1.jpg', name: 'John Doe', position: 'CEO', bio: 'John is the visionary behind our company.' },
  { id: 2, photo: '../assets/photo2.jpg', name: 'Jane Smith', position: 'CTO', bio: 'Jane leads our technology division.' },
  // Add more team members as needed
];

// Icons for mission section
const IntegrityIcon = () => <span>🔒</span>;
const InnovationIcon = () => <span>💡</span>;

const AboutUs = () => {
  const {  user } = useContext(AuthContext);

  const scrollToMission = () => {
    window.scrollTo({
      top: document.getElementById('our-mission').offsetTop,
      behavior: 'smooth'
    });
  };

  const navigate = (path) => {
    window.location.href = path;
  };

  return (
    <div>
      
     {
      user && <Navbar />
     }
      

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>About Us</h1>
          <p>Your trusted partner in excellence.</p>
          <button onClick={scrollToMission}>Learn More</button>
        </div>
      </section>

      {/* Our Mission Section */}
      <section id="our-mission" className="our-mission">
        <h2>Our Mission</h2>
        <p>We strive to deliver exceptional services that empower our clients to achieve their goals. Our mission is to innovate and lead the industry with integrity and excellence.</p>
        <div className="icons">
          {/* <div className="icon">
            <IntegrityIcon />
            <p>Integrity</p>
          </div>
          <div className="icon">
            <InnovationIcon />
            <p>Innovation</p>
          </div> */}
          {/* Add more icons as needed */}
        </div>
      </section>

      {/* Our Team Section */}
      {/* <section className="our-team">
        <h2>Meet the Team</h2>
        <div className="team-grid">
          {teamMembers.map(member => (
            <div key={member.id} className="team-card">
              <img src={member.photo} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.position}</p>
              <p>{member.bio}</p>
            </div>
          ))}
        </div>
      </section> */}

      {/* Our Story Section */}
      <section className="our-story">
        <h2>Our Story</h2>
        <p>Founded in [Year], we have grown from a small startup to a leader in the industry. Our journey has been marked by numerous milestones, including [notable achievements].</p>
        <div className="timeline">
          <div className="milestone">
            <h4>Year 1</h4>
            <p>Milestone description</p>
          </div>
          <div className="milestone">
            <h4>Year 2</h4>
            <p>Milestone description</p>
          </div>
          {/* Add more milestones as needed */}
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="call-to-action">
        <h2>Join Us</h2>
        <button onClick={() => navigate('../contactus')}>Contact Us</button>
      </section>

      {/* Footer Section */}
      {/* <footer className="about-footer">
        <div className="footer-content">
          <div className="links">
            <a href="/privacy">Privacy Policy</a>
            <a href="/terms">Terms of Service</a>
          </div>
          <div className="social-media">
            {/* Replace with actual social media icons }
            <span>🌐</span>
            <span>📘</span>
            <span>🐦</span>
          </div>
        </div>
      </footer> */}
      <MyFooter/>
    </div>
  );
};

export default AboutUs;
