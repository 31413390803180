// src/components/PasswordResetRequest.js

import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { motion } from 'framer-motion';
import './PasswordResetRequest.css';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import config from '../config';

function PasswordResetRequest() {
  const AUTH_URL = config.authUrl

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const csrfToken = Cookies.get('csrftoken');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("email: " + email);
        setLoading(true);
        try {
            const response = await axios.post(`${AUTH_URL}password_reset/`, {
                email });
            console.log(response.data, "email", email);
            setMessage(response.data.success);
            Swal.fire({
                title: "Password Reset Request Sent!!",
                text: "We've sent you an email with instructions to reset your password. Please check your inbox and click the link to create a new password. Please check your spam or junk folder if you don't see the email.",
                icon: "success"
            });
        } catch (error) {
            setMessage('Error sending password reset email.', error);
            console.log(error);
            Swal.fire({
                title: "Warning!",
                text: "User with this email does not exist",
                icon: "warning"
            });
        }finally{
            setLoading(false);
        }
    };

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="password-reset-container"
        >
            <h2 className='text-black text-2xl'>Request Password Reset</h2>
            <motion.form 
                onSubmit={handleSubmit}
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ type: 'spring', stiffness: 50 }}
                className="password-reset-form"
            >
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name='email'
                    className="password-reset-input"
                />
                <motion.div
                    type="submit"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="password-reset-button"
                    disabled={loading}
                >
                    <Button
                    variant="contained"
                    color="primary"
                    type='submit'
                    >
                    {loading ? (
                        <div className="spinner"></div>
                    ) : (
                        'Reset Password'
                    )}
                    </Button>
                </motion.div>
                
            </motion.form>
            {message && (
                <motion.p 
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    className="password-reset-message"
                >
                    {message}
                </motion.p>
            )}
        </motion.div>
    );
}

export default PasswordResetRequest;
