import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import MyFooter from '../Components/MyFooter'
import './settings.css';
import ThemeToggle from './ThemeToggle';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const Settings = () => {
    const [isNightMode, setIsNightMode] = useState(false);

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setIsNightMode(savedTheme === 'night');
        }
    }, []);

    useEffect(() => {
        document.body.className = isNightMode ? 'night' : 'day';
        localStorage.setItem('theme', isNightMode ? 'night' : 'day');
    }, [isNightMode]);

    const toggleTheme = () => {
        setIsNightMode(!isNightMode);
    };
    return (
        <div>
            <Navbar/>
            <div className='theme-changer'> 
            <Link to={`/changePass`}>

            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                <Button
                variant="contained"
                color="primary"
                className="password-reset-button"
                >
                Change Password
                </Button>
            </motion.div>
            </Link>
            {/* <ThemeToggle isNightMode={isNightMode} toggleTheme={toggleTheme} /> */}
            </div>
            <MyFooter />

        </div>
    )
}

export default Settings