import React, { useState, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import './contactus.css';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

function SendMail() {
    const form = useRef();
    const [loading, setLoading] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs
            .sendForm('service_l8f6dui', 'template_j76p5as', form.current, {
                publicKey: 'Y6FTptXmuZF4dRxke',
            })
            .then(
                () => {
                    setLoading(false);
                    form.current.reset(); // Clear the form
                    Swal.fire({
                        title: "Jajakallhu khairan",
                        text: "We've received your email. We'll reply to your email soon, Insh'Allah.",
                        icon: "success"
                    });
                },
                (error) => {
                    setLoading(false);
                    console.log('FAILED...', error.text);
                    Swal.fire({
                        title: "Warning!",
                        text: "Failed to send email.Try Again please !",
                        icon: "warning"
                    });
                }
            );
    };

    return (
        <div>
            <section className="contact-form-section">
                <h2>Get in Touch</h2>
                <form className="contact-form" ref={form} onSubmit={sendEmail}>
                    <div className="contact-form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" placeholder="Your Name" required />
                    </div>
                    <div className="contact-form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" placeholder="Your Email" required />
                    </div>
                    <div className="contact-form-group">
                        <label htmlFor="subject">Subject</label>
                        <input type="text" name="subject" id="subject" placeholder="Subject" required />
                    </div>
                    <div className="contact-form-group">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" rows="5" placeholder="Your Message" required></textarea>
                    </div>
                    <Button type="submit" disabled={loading}>
                        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Send Message"}
                    </Button>
                </form>
            </section>
        </div>
    );
}

export default SendMail;
