import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import config from '../config';

const API_URL = config.authUrl;
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() => {
        const storedTokens = localStorage.getItem('authTokens');
        return storedTokens ? JSON.parse(storedTokens) : null;
    });
  const csrfToken = Cookies.get('csrftoken');

    const [user, setUser] = useState(() => {
        const storedTokens = localStorage.getItem('authTokens');
        return storedTokens ? jwtDecode(JSON.parse(storedTokens).access) : null;
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        // fetchUserData();
        setLoading(false);
    }, [user]);
const fetchUserData = async () => {
  const csrfToken = Cookies.get('csrftoken');
console.log("the token i've gotten",csrfToken);
if(csrfToken){


  try {
    const response = await axios.get(`${API_URL}user/`, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
      withCredentials: true, // Ensure cookies are sent with the request
    });
    setUser(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
}else{
    return null;
}
};

    const loginUser = async (username, password) => {
        try {
            const response = await axios.post(API_URL + 'token/', { username, password });
            const data = response.data;
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            localStorage.setItem('authTokens', JSON.stringify(data));
            return data;
        } catch (error) {
            console.log("error at login ",error);
            // alert("Login Failed: Phone Number - Password does not exist");
            Swal.fire({
                title: "Warning!",
                text: "Login Failed: Phone Number - Password does not exist",
                icon: "warning"
            });
        }
    };

    const registerUser = async (first_name, username,email, password, password2) => {
        setErrors({});
        console.log("all data", first_name, username,email, password, password2);
        try {
            const response = await axios.post(API_URL + 'register/', { first_name, username,email, password, password2 });
            if (response.status === 201) {
                return response.data;
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            }
            // alert("Registration Failed: An error occurred during registration.");
            Swal.fire({
                title: "Warning!",
                text: "Registration Failed: An error occurred during registration.",
                icon: "warning"
            });
        }
    };

    const logoutUser = async () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');
        
        
        Cookies.remove('sessionid')
        Cookies.remove('csrftoken')
console.log("worked! logout")
    };

    const contextData = {
        user,
        setUser,
        authTokens,
        setAuthTokens,
        registerUser,
        loginUser,
        logoutUser,
        errors,
        fetchUserData

    };

    return (
        <AuthContext.Provider value={contextData}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
