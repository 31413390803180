import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './PasswordResetRequest.css';
import config from '../config';

function PasswordResetConfirm() {
  const AUTH_URL = config.authUrl
  
    
    const { uid, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const validatePassword = () => {
        if (password.length < 6) {
            return "Password must be at least 6 characters.";
        }
        if (password !== confirmPassword) {
            return "Passwords do not match.";
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationError = validatePassword();
        if (validationError) {
            setError(validationError);
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post(`${AUTH_URL}password_reset_confirm/${uid}/${token}/`, { password });
            setMessage(response.data.success);
            setPassword('');
            setConfirmPassword('');
            setError('');
            navigate('/');
        } catch (error) {
            setError('Error resetting password.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="password-reset-container"
        >
            <h2>Reset Password</h2>
            <motion.form 
                onSubmit={handleSubmit}
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ type: 'spring', stiffness: 50 }}
                className="password-reset-form"
            > 
                <div className="password-reset-input-wrapper">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter new password"
                        value={password}
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="password-reset-input"
                    />
                    <span className="password-toggle-icon" onClick={toggleShowPassword}>
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </span>
                </div>
                {error && <p className="password-reset-warning">{error}</p>}
                <div className="password-reset-input-wrapper">
                    <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        name="confirm_password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="password-reset-input"
                    />
                    <span className="password-toggle-icon" onClick={toggleShowConfirmPassword}>
                        <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                    </span>
                </div>
                {error && <p className="password-reset-warning">{error}</p>}
                <motion.button 
                    type="submit"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="password-reset-button"
                    disabled={loading}
                >
                    {loading ? (
                        <div className="spinner"></div>
                    ) : (
                        'Reset Password'
                    )}
                </motion.button>
            </motion.form >
            {message && <motion.p 
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                className="password-reset-message"
            >
                {message}
            </motion.p>}
        </motion.div>
    );
}

export default PasswordResetConfirm;
