import React, { useContext, useEffect, useState } from 'react'
import logo from '../assets/personal-report-logo.png'
import { Checkbox } from 'flowbite-react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './signup.css'
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2'
import LoginButton from '../Components/LoginButton';

import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

const Signup = () => {
    const [fullName,setFullName] = useState("");
    const [lastname,setLastName] = useState("");
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState("");
    const [phone,setPhone] = useState("");
    const [loading, setLoading] = useState(false);
    const { registerUser } = useContext(AuthContext);
    const navigate = useNavigate();
    //const phoneValidation = usePhoneValidation(phone);
    //-----for checking if the given phone number is valid or not--------------
    const phoneUtil = PhoneNumberUtil.getInstance();
    const { user } = useContext(AuthContext);

    //------show pass-----------
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);

    const isPhoneValid = (phone) => {
      try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
      } catch (error) {
        return false;
      }
    };

    const isValid = isPhoneValid(phone);

    const [touched, setTouched] = useState({
      fullName: false,
      email: false,
      password: false,
      confirmPassword: false,
      phone: false,
    });

    //-----for srolling to top during every load------
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const gotoLoginPage = () =>{ 
      let path = `/`; 
      navigate(path);
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const handleRegister = async (e) => {
        e.preventDefault();
        
        if (!fullName || !email || !password || !confirmPassword) {
          Swal.fire({
            title: "Warning!",
            text: "Please enter all required fields.",
            icon: "warning"
          });
          // alert("Please enter all required fields.");
          return;
        }
    
        if (!isValidEmail(email)) {
          Swal.fire({
            title: "Warning!",
            text: "Please enter a valid email address.",
            icon: "warning"
          });
          // alert("Please enter a valid email address.");
          return;
        }
        if (!isValid) {
          Swal.fire({
            title: "Warning!",
            text: "Please enter a valid Phone number.",
            icon: "warning"
          });
          // alert("Please enter a valid email address.");
          return;
        }
        if (password.length < 8) {
          Swal.fire({
            title: "Warning!",
            text: "Password must be at least 8 characters long.",
            icon: "warning"
          });
          // alert("Password must be at least 8 characters long.");
          return;
        }
    
        if (password !== confirmPassword) {
          Swal.fire({
            title: "Warning!",
            text: "Passwords do not match.",
            icon: "warning"
          });
          // alert("Passwords do not match.");
          return;
        }
    
        setLoading(true);
    
        try {
          const data = await registerUser(fullName,phone, email, password, confirmPassword);
          console.log("the res data : ",data)

          setLoading(false);
          if (data) {
            //alert("Registration Success. Now Go To Login Page!");
            Swal.fire({
              title: 'Registration Successful!',
              text: "Please activate your account by checking your email and clicking the activation link we sent you. Check your spam or junk folder if you don't see the email in your inbox.",
              icon: 'success',
              confirmButtonText: 'Ok.'
            }).then((result) => {
              if (result['isConfirmed']){
                // Put your function here
                navigate('/');
              }
            })
            
          }
        } catch (error) {
          setLoading(false);
          console.log("error at register : ", error);
          Swal.fire({
            title: "Warning!",
            text: "Registration Failed. Something went wrong.",
            icon: "warning"
          });
          // alert("Registration Failed. Something went wrong.");
        }
      };
    const handleToggle = () => {
      if (type==='password'){
          setIcon(eye);
          setType('text')
      } else {
          setIcon(eyeOff)
          setType('password')
      }
    }

    const handleBlur = (field) => {
      setTouched({ ...touched, [field]: true });
    };

  return (
    <div>
      {user && <Navbar/>}

      <div className='signup-not-user-top-part'>
        <img src={logo} alt='signup-logo'/>
        <div className='signup-not-user-top-part-title'>Daily Report</div>

      </div>
    
    <div className='signup-form'>
        
        <div className='signup-form-left-part'>
          <div className='signup-form-left-part-signup-title'>Sign Up</div>
            <form onSubmit={handleRegister}>
            {/* <label>UserName/Email: */}
            <input 
              placeholder='Full Name'
              type="text" 
              name="first_name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              onBlur={() => handleBlur('fullName')}
              required
              className='signup-form-left-part-input'
            />
            {touched.fullName && !fullName && <div style={{ color: 'red' }}>Please enter your full name.</div>}
            
            <div>
                
            <PhoneInput
              defaultCountry="bd"
              name='username'
              value={phone}
              style={{border:'1px',width:'100%'}}
              onChange={(phone) => setPhone(phone)}
              required
              onBlur={() => handleBlur('phone')}
            />

            {touched.phone && !isValid && <div style={{ color: 'red' }}>Phone is not valid</div>}

            </div>
            

            <div>
              <input 
                  placeholder='Email'
                  type="email" 
                  name="email" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  onBlur={() => handleBlur('email')}
                  className='signup-form-left-part-input'
              />
              {touched.email && !isValidEmail(email) && <div style={{ color: 'red' }}>Please enter a valid email.</div>}
            </div>
            {/* <input 
                placeholder='Phone'
                type="number" 
                name="phone"
                value={phone}
                onChange={(e)=> setPhone(e.target.value)}
            /> */}
            
            {/* </label> */}
            {/* <label>Password: */}
                

                <div>
                <div className='home-show-password'>
                <input 
                  placeholder='Password'
                  type={type} 
                  name="Password" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  onBlur={() => handleBlur('password')}
                  className='signup-form-left-part-input'
                  autoComplete="current-password"
                  />
                <span class="flex justify-around items-center" onClick={handleToggle}>
                    <Icon class="absolute mr-10" icon={icon} size={15}/>
                </span>
                </div>

                {touched.password && password.length < 8 && <div style={{ color: 'red' }}>Password must be 8 characters long.</div>}
                </div>
                
                <div>
                <div className='home-show-password'>
                <input 
                  placeholder='Confirm Password'
                  type={type} 
                  name="Password" 
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className='signup-form-left-part-input'
                  onBlur={() => handleBlur('confirmPassword')}
                  autoComplete="current-password"
                  />
                <span class="flex justify-around items-center" onClick={handleToggle}>
                    <Icon class="absolute mr-10" icon={icon} size={15}/>
                </span>
                </div>
                </div>
                
                {/* <p>Password must be 8 characters long.</p> */}
                {/* </label> */}
                {touched.confirmPassword && password !== confirmPassword && <div style={{ color: 'red' }}>Passwords don't match</div>}

                {/* {(!fullName || !email || !password || !confirmPassword) && <div style={{ color: 'red' }}>Please Enter all required fields.</div>} */}

                <Button className='signup-form-left-part-button' type='submit'> {loading ? 'Loading...' : 'SIGN UP'}</Button>

                <div style={{alignSelf:'center'}}>OR</div>
                {/* <GoogleOAuthProvider>
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      console.log(credentialResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    useOneTap
                  />
                </GoogleOAuthProvider> */}
                <LoginButton/>

            </form>

            <div className='signup-lower-part'>
              <div>Already have an account?</div>
              <span onClick={gotoLoginPage}>Login</span>
            </div>
             
            
        </div>
        
        <div className='signup-form-right-part'>

            <img src={logo} alt='signup-logo'/>

        </div>

    </div>
    <MyFooter/>
    </div>
  )
}

export default Signup