import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import login_page_ayat from '../assets/login_page_ayat.png';
import login_page_ayat_meaning from '../assets/login_page_ayat_meaning.png';
import './home.css';
import { Button } from 'react-bootstrap';
import config from '../config';

const bgColorChange = keyframes`
  0% { background-color: #12B48D; }
  100% { background-color: #12B48D; }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: ${bgColorChange} 10s infinite;
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, #72EDF2 10%, #6ae551 100%);


  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
`;

const AnimatedText = styled(motion.h1)`
  font-size: 2em;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const AnimatedButton = styled(Button)`
  padding: 10px 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  color: #12B48D;
  outline: none;
  margin-top: 20px;
  width: 100%;
  max-width: 300px;

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 8px 16px;
  }
`;

const buttonVariants = {
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.3,
      yoyo: Infinity // Repeat animation in both directions
    }
  }
};

const textVariants = {
  animate: {
    y: [0, -20, 0], // Animate text vertically
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse"
    }
  }
};

const Activate = () => {
  let navigate = useNavigate(); 
  const { uid, token } = useParams();
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(null);
  const API_URL  = config.authUrl;

  const handleVerify = async () => {
    try {
      const response = await axios.get(`${API_URL}activate/${uid}/${token}/`);
      if (response.status === 200) {
        setVerified(true);
      }
    } catch (error) {
      setError('Activation failed. Please try again.');
    }
  };

  return (
    <PageContainer>
      <ContentContainer>
        <div className='home-middle-quranic-verse'>
          <img src={login_page_ayat} className='login_page_ayat' alt='ayat' />
          <img src={login_page_ayat_meaning} className='login_page_ayat_meaning' alt='ayat-meaning' />
        </div>
        
        <div>
          {verified ? (
            <AnimatedText>Your account has been successfully verified!
              Proceed to <Link to='/'><Button className='signup-form-left-part-button'>login</Button> </Link>
            </AnimatedText>
          ) : (
            <div>
              <AnimatedText variants={textVariants} animate="animate">
                Please verify your account
              </AnimatedText>
              <AnimatedButton
                variants={buttonVariants}
                whileHover="hover"
                onClick={handleVerify}
              >
                Verify your account
              </AnimatedButton>
              {error && <AnimatedText>{error}</AnimatedText>}
            </div>
          )}
        </div>
      </ContentContainer>
    </PageContainer>
  );
};

export default Activate;
