export default {
  // apiUrl:"http://127.0.0.1:8000/api/",
  // authUrl:"http://127.0.0.1:8000/",
  apiUrl:"https://api.amardailyreport.com/api/",
  authUrl:"https://api.amardailyreport.com/"
  // apiUrlLogin:"http://127.0.0.1:8000/login/",
  // apiUrlChangepass:"http://127.0.0.1:8000/changepass/"

  //apiUrl: "https://reporting.smsamfund.se/HvOakN1AD9c2Stp/api/",
  //apiUrlLogin: "https://reporting.smsamfund.se/HvOakN1AD9c2Stp/login/",
  //apiUrlChangepass: "https://reporting.smsamfund.se/HvOakN1AD9c2Stp/changepass/",
};