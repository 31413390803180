import React, { useContext ,useRef} from 'react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './contactus.css'
import AuthContext from '../context/AuthContext';
import SendMail from './SendMail';



const ContactUs = () => {
  const {  user } = useContext(AuthContext);
 
  return (
    
    <div>
     { user && <Navbar/>}
      {/* Hero Section */}
      <section className="contact-hero">
        <div className="contact-hero-content">
          <h1>Contact Us</h1>
          <p>We’re here to help and answer any question you might have.</p>
        </div>
      </section>

      {/* Contact Form Section */}
      <SendMail/>

      {/* Contact Information Section */}
      <section className="contact-info">
        <h2>Our Contact Information</h2>
        <div className="info">
          <div>
            <h3>Address</h3>
            <p>123 Main Street, Anytown, USA</p>
          </div>
          <div>
            <h3>Phone</h3>
            <p><a href="tel:+1234567890">(123) 456-7890</a></p>
          </div>
          <div>
            <h3>Email</h3>
            <p><a href="mailto:info@company.com">info@company.com</a></p>
          </div>
          <div className="contact-social-media">
            {/* Replace with actual social media icons */}
            <span>🌐</span>
            <span>📘</span>
            <span>🐦</span>
          </div>
        </div>
      </section>

      {/* Map Section */}
      <section className="contact-map">
        <h2>Find Us Here</h2>
        <div className="contact-map-container">
          <iframe title="Our Place"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093145!2d144.9537363153193!3d-37.81627997975171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d1e3bbee0b0c!2s123+Main+St%2C+Melbourne+VIC+3000%2C+Australia!5e0!3m2!1sen!2sus!4v1519572013796"
            width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" ></iframe>
        </div>
      </section>

      {/* Footer Section */}
      {/* <footer className="contact-footer">
        <div className="footer-content">
          <div className="links">
            <a href="/privacy">Privacy Policy</a>
            <a href="/terms">Terms of Service</a>
          </div>
          <div className="social-media">
            {/* Replace with actual social media icons }
            <span>🌐</span>
            <span>📘</span>
            <span>🐦</span>
          </div>
        </div>
      </footer> */}
      <MyFooter/>
    </div>
  );
};

export default ContactUs;
